const NoRefillsIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="160" height="160" viewBox="0 0 160 160" fill="none">
            <path
                d="M1.81836 80.3352C1.81836 140.688 67.1516 178.408 119.418 148.232C143.675 134.227 158.618 108.345 158.618 80.3352C158.618 19.9829 93.2851 -17.7374 41.0184 12.4389C16.7612 26.4437 1.81836 52.3256 1.81836 80.3352Z"
                fill="#F2F9FC"
            />
            <path
                d="M47.4775 136.46C47.4614 139.062 70.4697 140.691 88.8928 139.392C97.4558 138.788 102.73 137.67 102.722 136.46C102.738 133.857 79.7299 132.228 61.3068 133.528C52.7438 134.132 47.47 135.25 47.4775 136.46Z"
                fill="#5CC4ED"
            />
            <path
                d="M129.965 125.733H27.9434C25.0789 125.731 22.7573 123.409 22.7573 120.544V49.6492H135.153V120.544C135.153 123.41 132.83 125.732 129.965 125.733Z"
                fill="#5CC4ED"
                stroke="black"
                strokeWidth="1.5"
            />
            <path
                d="M129.965 34.0868H27.9434C25.0789 34.0879 22.7573 36.4105 22.7573 39.275V49.6492H135.153V39.275C135.153 36.4097 132.83 34.087 129.965 34.0868Z"
                stroke="#222A3F"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M27.9458 41.8681C27.9408 43.8642 30.0986 45.1172 31.8298 44.1234C32.6373 43.6599 33.1343 42.7991 33.1319 41.8681C33.1369 39.8719 30.9791 38.6189 29.2479 39.6127C28.4405 40.0762 27.9434 40.937 27.9458 41.8681Z"
                fill="#5CC4ED"
                stroke="#222A3F"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M38.061 41.8681C38.0561 43.8642 40.2138 45.1172 41.945 44.1234C42.7526 43.6599 43.2495 42.7991 43.2471 41.8681C43.2521 39.8719 41.0943 38.6189 39.3631 39.6127C38.5558 40.0762 38.0588 40.937 38.061 41.8681Z"
                fill="#5CC4ED"
                stroke="#222A3F"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M48.1768 41.8681C48.1718 43.8642 50.3297 45.1172 52.0609 44.1234C52.8683 43.6599 53.3652 42.7991 53.363 41.8681C53.368 39.8719 51.2102 38.6189 49.479 39.6127C48.6715 40.0762 48.1745 40.937 48.1768 41.8681Z"
                fill="#5CC4ED"
                stroke="#222A3F"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M90.3667 43.7123C90.3667 51.8822 93.6122 59.7175 99.3892 65.4945C105.166 71.2716 113.002 74.517 121.171 74.517C129.341 74.517 137.177 71.2716 142.954 65.4945C148.731 59.7175 151.976 51.8822 151.976 43.7123C151.976 35.5423 148.731 27.707 142.954 21.93C137.177 16.153 129.341 12.9075 121.171 12.9075C113.002 12.9075 105.166 16.153 99.3892 21.93C93.6122 27.707 90.3667 35.5423 90.3667 43.7123Z"
                fill="#5CC4ED"
                stroke="#222A3F"
                strokeWidth="1.5"
            />
            <path
                d="M132.067 39.1789L125.215 46.6743L112.951 35.4646L119.802 27.9679C120.535 27.1496 121.422 26.4847 122.413 26.0116C123.405 25.5386 124.48 25.2667 125.577 25.2118C126.674 25.1569 127.771 25.32 128.804 25.6918C129.838 26.0635 130.787 26.6364 131.598 27.3775C132.409 28.1185 133.064 29.0129 133.527 30.009C133.99 31.005 134.251 32.0829 134.294 33.1804C134.338 34.2779 134.163 35.3731 133.781 36.4027C133.399 37.4323 132.816 38.3759 132.067 39.1789Z"
                fill="white"
                stroke="#222A3F"
                strokeWidth="1.5"
            />
            <path
                d="M118.374 54.1653L125.216 46.6742L112.951 35.4646L106.104 42.9614C105.368 43.7671 104.799 44.7098 104.427 45.7357C104.056 46.7615 103.89 47.8505 103.94 48.9404C104.04 51.1416 105.01 53.2128 106.637 54.6986C108.264 56.1843 110.415 56.9628 112.616 56.8628C114.817 56.7628 116.889 55.7925 118.374 54.1653Z"
                fill="#F2F9FC"
                stroke="#222A3F"
                strokeWidth="1.5"
            />
            <path
                d="M119.12 57.0719C120.7 58.6526 122.714 59.729 124.907 60.1651C127.099 60.6012 129.371 60.3774 131.437 59.522C133.502 58.6666 135.267 57.218 136.509 55.3594C137.751 53.5008 138.414 51.3156 138.414 49.0803C138.414 46.8449 137.751 44.6598 136.509 42.8011C135.267 40.9425 133.502 39.4939 131.437 38.6385C129.371 37.7831 127.099 37.5593 124.907 37.9954C122.714 38.4315 120.7 39.508 119.12 41.0886C117 43.2082 115.81 46.0828 115.81 49.0803C115.81 52.0777 117 54.9524 119.12 57.0719Z"
                fill="white"
                stroke="#222A3F"
                strokeWidth="1.5"
            />
            <path
                d="M133.614 58.3276C134.145 57.9518 134.643 57.5317 135.103 57.072C135.563 56.6126 135.982 56.1145 136.357 55.5831L120.608 39.8345C119.544 40.5857 118.616 41.5134 117.865 42.5775L133.614 58.3276Z"
                fill="#F2F9FC"
                stroke="#222A3F"
                strokeWidth="1.5"
            />
        </svg>
    );
};

export default NoRefillsIcon;
