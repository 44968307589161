import { ReactElement } from 'react';
import CheckMarkIcon from 'ui-kit/icons/check-mark-icon';
import './auto-refill-badge.style.scss';

interface AutoRefillBadgeProps {
    text: string;
}

const AutoRefillBadge = ({ text }: AutoRefillBadgeProps): ReactElement => (
    <div className="refill-badge">
        <CheckMarkIcon fill="#222a3f" />
        <p>{text}</p>
    </div>
);
export default AutoRefillBadge;
