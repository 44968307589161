import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// UI Kit & Components
import SidebarColumn from 'components/sidebar-column/sidebar-column.component';
import CartIcon from 'ui-kit/icons/cart-icon/cart-icon';
import EasyRefillShippingAddress from './easy-refill-shipping-address/easy-refill-shipping-address.component';
import EasyRefillShippingMethod from './easy-refill-shipping-method/easy-refill-shipping-method.component';
import EasyRefillPaymentMethod from './easy-refill-payment-method/easy-refill-payment-method.component';
import EasyRefillPrescriptionInformation from './easy-refill-prescription-information/easy-refill-prescription-information.component';
import EasyRefillHealthProfile from './easy-refill-health-profile/easy-refill-health-profile.component';
import { EasyRefillCartTotal } from './easy-refill-cart-total/easy-refill-cart-total.component';
import EmptyCart from 'components/medicine-cabinet-cart/empty-cart/empty-cart.component';

// Utils
import { EXPEDITED_SHIPPING_COST, EXPEDITED_SHIPPING_ID } from 'util/cart';
import {
    easyRefillPaymentCardsSelector,
    easyRefillRxsSelector,
    easyRefillCartItemsTotalSelector,
    easyRefillPlansSelector,
    easyRefillOrderBillShipMethodSelector,
    easyRefillOrderPaymentCardSelector,
    cartSelector,
    easyRefillAccountHasInsuranceSelector,
    easyRefillcartSubtotalSelector
} from 'state/easy-refill/easy-refill.selectors';

import './refill-details-cart.style.scss';

const RefillDetailsCart: React.FC = () => {
    const { t } = useTranslation();

    const cartObject = useSelector(cartSelector);
    const accountPlans = useSelector(easyRefillPlansSelector);
    const shipMethodId = useSelector(easyRefillOrderBillShipMethodSelector);
    const allPaymentData = useSelector(easyRefillPaymentCardsSelector);
    const cartItemsTotal = useSelector(easyRefillCartItemsTotalSelector);
    const rxResults = useSelector(easyRefillRxsSelector);
    const selectedPaymentMethod = useSelector(easyRefillOrderPaymentCardSelector);
    const accountHasInsurance = useSelector(easyRefillAccountHasInsuranceSelector);
    const estimatedSubtotal = useSelector(easyRefillcartSubtotalSelector);

    return (
        <SidebarColumn
            className="refill-details-cart"
            isCartOnly
            cartTotalItems={cartItemsTotal}
            cartLabel={t('components.medicineCabinetCart.responsiveCart.cartButtonLabel')}
        >
            <div className="refill-details-cart__header">
                <CartIcon color="black" className={'header-icon-container'} />
                <h3>{t('pages.easyRefill.prescriptions.responsiveCart.cartButtonLabel')}</h3>
            </div>

            {cartItemsTotal && cartItemsTotal > 0 ? (
                <>
                    <EasyRefillShippingAddress />
                    <EasyRefillShippingMethod />
                    <EasyRefillPaymentMethod
                        selectedPaymentMethod={selectedPaymentMethod}
                        paymentData={allPaymentData || []}
                        showSelectCardRadioInput
                        showSetDefaultLink
                    />
                    <EasyRefillHealthProfile />
                    <EasyRefillPrescriptionInformation
                        cartObject={cartObject}
                        isInsuranceAccount={accountHasInsurance}
                        prescriptions={rxResults}
                        accountPlans={accountPlans}
                    />
                    <EasyRefillCartTotal
                        cartObject={cartObject}
                        prescriptionsObject={rxResults}
                        isUnknownPrice={false}
                        initialOrderPrice={String(estimatedSubtotal)}
                        currentShippingPrice={shipMethodId === EXPEDITED_SHIPPING_ID ? EXPEDITED_SHIPPING_COST : 0}
                        isInsuranceAccount={accountHasInsurance}
                    />
                </>
            ) : (
                <EmptyCart
                    title={t('components.medicineCabinetCart.empty.title')}
                    body={t('components.medicineCabinetCart.empty.body')}
                />
            )}
        </SidebarColumn>
    );
};

export default RefillDetailsCart;
