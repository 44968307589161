import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Components & UI
import { CartShipping } from 'components/cart/cart-shipping.component';
import ColumnSectionEditModeToggle from 'components/sidebar-column/column-section-toggle/column-section-toggle.component';

// Utils
import { EXPEDITED_SHIPPING_ID, DEFAULT_SHIPPING_ID } from 'util/cart';
import { CartShippingProps } from 'components/cart/types';

// EasyRefill
import { easyRefillOrderBillShipMethodSelector } from 'state/easy-refill/easy-refill.selectors';
import {
    easyRefillGetCartRoutine,
    easyRefillUpdateShippingMethodRoutine
} from 'state/easy-refill/easy-refill.routines';

// Modals
import { FailureUpdateCartModalContent } from 'pages/secure/cart/intra-page-items/_cart-update-modal-item';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';

import './easy-refill-shipping-method.style.scss';

const EasyRefillShippingMethod: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const shipMethodId = useSelector(easyRefillOrderBillShipMethodSelector);

    const showShippingError = useCallback(() => {
        dispatch(
            openModal({
                showClose: true,
                className: 'prescription-modal',
                bodyContent: <FailureUpdateCartModalContent area={t('modals.updateCart.areas.shippingOption')} />,
                ctas: [
                    {
                        label: t('modals.updateCart.labels.gotIt'),
                        variant: 'primary',
                        onClick: () => {
                            dispatch(closeModal({}));
                        },
                        dataGALocation: 'UpdateCartError'
                    }
                ]
            })
        );
    }, [dispatch, t]);

    const handleDefaultShippingClick = useCallback<NonNullable<CartShippingProps['handleDefaultShippingClick']>>(() => {
        dispatch(
            easyRefillUpdateShippingMethodRoutine({
                orderHighPriority: false,
                shipMethodId: DEFAULT_SHIPPING_ID,
                onSuccess: () => {
                    dispatch(easyRefillGetCartRoutine.trigger());
                },
                onFailure: () => {
                    showShippingError();
                    dispatch(easyRefillGetCartRoutine.trigger());
                    return false;
                }
            })
        );
    }, [dispatch, showShippingError]);

    const handleExpeditedShippingClick = useCallback<
        NonNullable<CartShippingProps['handleDefaultShippingClick']>
    >(() => {
        dispatch(
            easyRefillUpdateShippingMethodRoutine({
                orderHighPriority: true,
                shipMethodId: EXPEDITED_SHIPPING_ID,
                onSuccess: () => {
                    dispatch(easyRefillGetCartRoutine.trigger());
                },
                onFailure: () => {
                    showShippingError();
                    dispatch(easyRefillGetCartRoutine.trigger());
                    return false;
                }
            })
        );
    }, [dispatch, showShippingError]);

    return (
        <ColumnSectionEditModeToggle
            bodyClassName="easy-refill-shipping-method__content"
            className="easy-refill-shipping-method"
            editModeContent={
                <CartShipping
                    handleDefaultShippingClick={handleDefaultShippingClick}
                    handleExpeditedShippingClick={handleExpeditedShippingClick}
                    shipMethodId={shipMethodId}
                    t={t}
                />
            }
            headerClassName="easy-refill-shipping-method__header"
            title={t('components.medicineCabinetCart.shippingMethod.title')}
        >
            <>
                <p className="easy-refill-shipping-method__selected">
                    {t(
                        `components.medicineCabinetCart.shippingMethod.${
                            shipMethodId === EXPEDITED_SHIPPING_ID ? 'expeditedShipping' : 'freeShipping'
                        }`
                    )}
                </p>
                <div className="easy-refill-shipping-method__note">
                    {t('components.medicineCabinetCart.shippingMethod.footer')}
                </div>
            </>
        </ColumnSectionEditModeToggle>
    );
};

export default EasyRefillShippingMethod;
