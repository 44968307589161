import { MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';

// UI Kit
import CreditCardIcon from 'ui-kit/icons/creditcard-icon/creditcard-icon';

// Components
import EmptyAlertBox from 'components/medicine-cabinet-cart/empty-alert-box/empty-alert-box';

// Easy Refill
import { PaymentCard } from 'types/easy-refill';

// Utils
import { ellipsify, lowercaseAndCapitalize } from 'util/cart';
import { addZero } from 'util/number';
import { getCreditCardEnding } from 'util/payments';

// Styles
import './selected-payment.style.scss';

interface SelectedPaymentProps {
    selectedPaymentMethod?: PaymentCard;
    creditCardsData?: PaymentCard[];
    isInnerWidthViewport?: MutableRefObject<boolean>;
}

const SelectedPayment: React.FC<SelectedPaymentProps> = (props) => {
    const { selectedPaymentMethod, creditCardsData, isInnerWidthViewport } = props;

    const { t } = useTranslation();

    const hasPaymentData = creditCardsData && creditCardsData.length > 0;

    return (
        <>
            {hasPaymentData ? (
                <>
                    {selectedPaymentMethod && (
                        <div className="selected-payment">
                            <div className="selected-payment-type">
                                {t(
                                    `components.medicineCabinetCart.paymentMethod.${
                                        selectedPaymentMethod.defaultCard ? 'primaryPayment' : 'secondaryPayment'
                                    }`
                                )}
                            </div>
                            <div className="selected-payment-info">
                                <div className="selected-payment-info-text">
                                    <p>{`${
                                        isInnerWidthViewport?.current
                                            ? ellipsify(lowercaseAndCapitalize(selectedPaymentMethod.cardType))
                                            : selectedPaymentMethod.cardType
                                    } ${getCreditCardEnding(selectedPaymentMethod.secureCardNumber, '····')}`}</p>
                                    <p>{` ${selectedPaymentMethod.cardName}`}</p>
                                    <p>
                                        {t('components.medicineCabinetCart.paymentMethod.expiry', {
                                            month: addZero(selectedPaymentMethod.cardMonthNum),
                                            year: selectedPaymentMethod.cardYear.slice(-2)
                                        })}
                                    </p>
                                </div>
                                <CreditCardIcon
                                    className="payment-card__credit-card-icon"
                                    variant={selectedPaymentMethod.cardType}
                                />
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <EmptyAlertBox text={t('pages.cart.emptyPaymentMethod')} />
            )}
        </>
    );
};

export default SelectedPayment;
